import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarComponent, NotificationDialog } from './top-bar/top-bar.component';
import {  MatSidenavModule, MatIconModule, MatMenuModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import { NotificationService } from './top-bar/notification.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { AutoCompleteComponent } from './auto-complete/auto-complete.component';


@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    RouterModule,
    MatSidenavModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatMenuModule
  ],
  declarations: [TopBarComponent,NotificationDialog , AutoCompleteComponent ],
  exports: [TopBarComponent,NotificationDialog , AutoCompleteComponent],
  entryComponents: [NotificationDialog , AutoCompleteComponent],
  providers: [NotificationService]
})
export class CommonModulesModule { }
