
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadingStrategy, Route } from '@angular/router';
import { AuthGuard } from './utilities/route-gaurds/auth-gaurd';


const appRoutes: Routes = [

  {
    path: '',
    canActivateChild: [AuthGuard],
    loadChildren: './modules/onboarding/onboarding.module#OnboardingModule',
    data: { preload: false },
  },
  {
    path: 'pages',
    canActivateChild: [AuthGuard],
    loadChildren: './modules/pages/pages.module#PagesModule',
  },
  {
    path: 'invoice-details/:id',
    canActivateChild: [AuthGuard],
    loadChildren: './modules/miscellaneous/miscellaneous.module#MiscellaneousModule',
  },
  // {
  //   path: '',
  //  redirectTo:'/login',
  //  pathMatch:'full'
  // },
  { path: '**', redirectTo: '/pages/' }
];
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
