import { Injectable } from '@angular/core';
import { Socket } from "socket.io";
import { environment } from 'src/environments/environment';
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
declare var io:any;

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private visited = false;
  setVisited() {
    this.visited = true;
  }
  isVisited(): boolean {
    return this.visited;
  }

  socket: any;
  socketEvent = new EventEmitter();
  constructor() { }

  connect() {
    let access_token = localStorage.getItem('accessToken');
    let socket_url = `${environment.api_url}?token=${access_token}`;
    this.socket = io.connect(socket_url);
    console.log('=======>connected')
  }

  emit(event: string, data: any) {
    this.socket.emit(event, data);
  }
  on(event: string) {

    return Observable.create(observer => {
      this.socket.on(event, data => {
        observer.next(data);
      });
    })
  }

  disconnect() {
    this.socket.disconnect();
  }
}


