import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map, catchError } from 'rxjs/operators'
import { throwError } from 'rxjs';
import { MatSnackBar } from '../../../../../node_modules/@angular/material';
import { CommonService } from '../../../utilities/services/common.service';

@Injectable()
export class NotificationService {

    constructor(private http: HttpClient, private commonService: CommonService,private matSnackBar: MatSnackBar) { }
    notify(payload: Object,single?:number) {
      let access_token = !single? 'Bearer ' + localStorage.getItem('accessToken'):undefined;

        return this.http.post(`${environment.api_url}/admin/sendNotificationToCustomer`,payload,{headers:{'authorization': access_token ,'content-language': 'en'} })
            .pipe(map((res: any) => { if (res.statusCode === 200) {  return res } else
             { console.error(res); this.matSnackBar.open(res.message, 'Error', {
                duration: 2000,
              }); return;} })
            )
    }

  markRead() {
    let payload={
      'markAllAsRead':1
    }
    let access_token = 'Bearer ' + localStorage.getItem('accessToken');
    let url = `${environment.api_url}/pushNotification/markAllAsRead`;
    return this.http.post(url,payload, { headers: { 'authorization': access_token, 'content-language': 'en' } })
      .pipe(map((res: any) => {
        if (res.statusCode === 200) { return res } else {
          if (res.statusCode === 401) {
            // this.handleSession();
          }
          else {
            this.matSnackBar.open(res.message, 'Error', {
              duration: 2000,
            });
          }
          return;
        }
      }));

  }

    getAllNotifications(payload?,single?:number) {
      let access_token = !single? 'Bearer ' + localStorage.getItem('accessToken'):undefined;

        return this.http.get(`${environment.api_url}/pushNotification/getNotificationList?limit=${payload*5}`,{headers:{'authorization': access_token ,'content-language': 'en'} })
            .pipe(map((res: any) => { if (res.statusCode === 200) {  return res } else
             { console.error(res); this.matSnackBar.open('Something went wrong', 'Error', {
                duration: 2000,
              }); return;} })
            )
    }
}