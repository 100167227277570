import { BrowserModule, } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonService } from './utilities/services/common.service';
import { AuthGuard } from './utilities/route-gaurds/auth-gaurd';
import { CommonModulesModule } from 'src/app/utilities/common-modules/common-modules.module';
import {  MatSnackBarModule } from '../../node_modules/@angular/material';
import { BaThemeSpinner } from './utilities/services/baThemeSpinner.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { GoogleChartsModule } from 'angular-google-charts';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    routing,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModulesModule,
    MatSnackBarModule,
    ToastrModule.forRoot(),
    GoogleChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    CommonService,
    AuthGuard,
    BaThemeSpinner
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
