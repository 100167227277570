import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from './notification.service';
import { MatSnackBar, MatMenuTrigger } from '@angular/material';
import { SocketService } from '../../services/socket.service';
import { takeWhile, debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';




export interface DialogData {
  message: string;
}

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  message: string;
  customer: any[];
  notificationCount;
  notificationList = [];
  skip: number = 1;
  showLoader: boolean = false;
  alive = true;


  @ViewChild('clickHoverMenuTrigger', { static: false }) clickHoverMenuTrigger: MatMenuTrigger;
  showCustomerActionButtons: boolean = false;
  menuItems: { label: string; icon: string; navigate: string; }[];
  pushNotificationIds =
  {
    CUSTOM_NOTIFICATION : -1,
    SIGNUP_FOR_CUSTOMER : 0,
    ACCOUNT_VERIFIED_BY_ADMIN : 1,
    ACCOUNT_REJECTED_BY_ADMIN : 2,
    CHECKIN_FORMS_VERIFIED : 3,
    CHECKIN_FORMS_REJECTED : 4,
    SUPERVISED_BAIL_APPLIED : 5,
    REMINDER_FOR_SUPERVISED_BAIL : 6,
    CHECKIN_FORMS_READY_FOR_SIGNATURE : 7,
    CHECKIN_FORMS_SIGNED : 8,
    CHECKIN_FORMS_SUBMITTED : 9,
    NEW_CUSTOMER_REGISTERED : 10,
    PAYMENT_SUCCESSFUL : 11,
    PAYMENT_FAILED : 12,
    USER_OUSIDE_OF_GEOFENCE : 13
  };

  constructor(private router: Router, public commonService: CommonService, private socketService: SocketService,
    public dialog: MatDialog, public notify: NotificationService, private _snackBar: MatSnackBar, private toastr: ToastrService) { }

  ngOnInit() {
    this.socketService.connect();


    this.socketService.on('notificationForAdmin').pipe(takeWhile(() => this.alive)).subscribe(data => {
      console.log('[sockets]', data);
      this.toastr.info(data.notification.body, data.notification.title, {
        timeOut: 4000,
        tapToDismiss: false,

        closeButton: true,
      });
      this.getNotifications();

    });

    this.menuItems = [
      {
        label: 'Dashboard',
        icon: 'dashboard',
        navigate: 'dashboard'
      },
      {
        label: 'Customer',
        icon: 'person',
        navigate: 'customer'
      },
      {
        label: 'Supervised Bail',
        icon: 'person',
        navigate: 'supervised-bail'
      },
      {
        label: 'Court Dates',
        icon: 'calendar_today',
        navigate: 'appointment'
      },
      {
        label: '24 Hours Check in',
        icon: 'timer',
        navigate: 'check-in'
      },
      {
        label: 'Settings',
        icon: 'settings_applications',
        navigate: 'settings'
      },
      {
        label: 'Admin User',
        icon: 'person',
        navigate: 'admin-user'
      },
      {
        label: 'Logs',
        icon: 'person',
        navigate: 'logs'
      },
      {
        label: 'Logout',
        icon: 'power_settings_new',
        navigate: 'logout'
      },
    ];

    if(localStorage.getItem("isSuperAdmin") != null){
      if(!localStorage.getItem("isSuperAdmin")){
        for(var i=0; i<this.menuItems.length; i++){
          if(this.menuItems[i].label == "Logs"){
            this.menuItems.splice(i, 1);
          }
        }

      }
    }
    this.getNotifications();


    if (!this.socketService.isVisited()) {
      this.socketService.setVisited();
      (function intializeHippo() {
        (<any>window).initHippo({
          'appSecretKey': JSON.parse(localStorage.getItem('hippotoken')).agent_secret_key,
        });

      (<any>window).fuguInitAgent({
          'agent_secret_key': JSON.parse(localStorage.getItem('hippotoken')).agent_secret_key,
          'user_unique_keys': [], // array of specific user unique keys of which you want unread count, empty array otherwise.
          'is_fork': 1
        });

      })();
    }



    if (this.router.url === '/pages/customer/list-customers' || this.router.url === '/pages/watch-customer/watch-list-customers') {
      this.showCustomerActionButtons = true;
    }
    else {
      this.showCustomerActionButtons = false;
    }
  }

  getNotifications() {
    this.showLoader = true;
    this.notify.getAllNotifications(this.skip).subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.showLoader = false;

          this.notificationList = response.data.notifications;
          this.notificationCount = response.data.unreadCount;
        }

      })

  }
  vieMoreNotifications(event) {
    this.skip++;
    this.getNotifications();
  }
  markAllAsRead() {
    this.notify.markRead().subscribe(
      (response: any) => {
        if (response.statusCode == 200) {
          this.showLoader = false;
          this.getNotifications();


        }

      })
  }
  navigate(index) {
    if (this.menuItems[index].navigate === 'logout') {
      this.commonService.logout();
    } else {
      this.router.navigate([`/pages/${this.menuItems[index].navigate}`]);
    }
  }

  notification() {
    const dialogRef = this.dialog.open(NotificationDialog, {
      width: '450px',
      data: { message: this.message }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.message = result;
      this.customer = this.commonService.sendData();
      console.log(this.customer, 'this.customer');

      let payload = {
        title: "Message",
        message: this.message,
      };
      this.message = '';

      if (this.customer[0] == 1) {
        payload['allCustomers'] = true;
      }
      else {
        payload['customerIds'] = this.customer;
      }
      this.notify.notify(payload).subscribe(
        (response: any) => {
          if (response.statusCode == 200) {
            this._snackBar.open("Notification sent", "", {
              duration: 2000,
            });
          }

        })


    });
  }

  redirectNotfication(data) {
    if (data.notificationId == this.pushNotificationIds.NEW_CUSTOMER_REGISTERED || data.notificationId == this.pushNotificationIds.SIGNUP_FOR_CUSTOMER) {
      this.router.navigate(['/pages/customer']);
    }if (data.notificationId == this.pushNotificationIds.CHECKIN_FORMS_SIGNED || data.notificationId == this.pushNotificationIds.CHECKIN_FORMS_SUBMITTED) {
      this.router.navigate(['/pages/check-in']);
    }if(data.notificationId == this.pushNotificationIds.SUPERVISED_BAIL_APPLIED){
      this.router.navigate(['/pages/customer/view-customer', data.customer_id])
    } else {
      return;
    }
  }
  ngOnDestroy() {
    this.alive = false;

  }

}

@Component({
  selector: 'notification-dialog',
  templateUrl: 'notification-dialog.html',
  styleUrls: ['./top-bar.component.scss']
})
export class NotificationDialog {

  constructor(
    public dialogRef: MatDialogRef<NotificationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
