import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { SocketService } from './socket.service';


@Injectable()
export class CommonService {
  constructor(public router :Router,public matSnackBar: MatSnackBar,private socketService:SocketService){}

  customers=[]
  users_to_notify(data: any){
    this.customers=data;
  }

  generateGetUrl(url,payload){
    let isFirst = true;
    for (const key in payload) {
      const value = payload[key];
      if (value) {
        isFirst?url+='?':url+='&';
        url += `${key}=${value}`;
        isFirst = false;
      }
    }
    return url;
  }

  sendData(){
    return this.customers;
  }

  public checkLoggedIn()
  {
    if(localStorage.getItem('accessToken'))
    return true;
    else
    return false;
  }
  logout(){
    window.localStorage.clear();
    this.socketService.disconnect();

    this.router.navigate(['/login']);
  }
    public response(res: any, index: number) {
        if (!(res.status === 200)) {
          throw new Error(res.message);
        }
        else{
        console.log(res);
          return res;
        }
      }
      handleSession()
      {
        this.matSnackBar.open('Session Expired', 'Error', {
          duration: 2000,
        });
     setTimeout(() => {
          this.logout();
     }, 2000);
      
      }
}
