
import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
declare var google: any;
@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit, AfterViewInit {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() onInputBlur: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext',{static:true}) addresstext: any;

  autocompleteInput: string = '';
  @Input() disabled: boolean = false;
  private _inputAddress;
  get inputAddress() { return this._inputAddress };
  @Input() set inputAddress(val: string) {
      if (val) {
          this._inputAddress = val;
          this.autocompleteInput = val;
      }
  };
  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
      this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
      const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
          {
              types: []  // 'establishment' / 'address' / 'geocode'
          });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace();
          this.invokeEvent(place);
      });
  }

  invokeEvent(place: Object) {
      this.setAddress.emit(place);
  }

  onBlur(ev){
    console.log('adkjcbkjwdbc',ev);
    this.onInputBlur.emit({name:ev.target.value})
  }

}

